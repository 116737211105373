var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"talents_box"},[_c('loading',{attrs:{"loading":_vm.timesSpining}}),_c('div',{staticClass:"talents"},[_c('div',{staticClass:"talents_condition"},[_c('div',{staticClass:"talents_condition_inp"},[_c('div',{staticClass:"talents_condition_inpName"},[_vm._v("姓名：")]),_c('a-input',{staticStyle:{"width":"272px"},attrs:{"allowClear":"","placeholder":"请输入"},model:{value:(_vm.formInline.realName),callback:function ($$v) {_vm.$set(_vm.formInline, "realName", $$v)},expression:"formInline.realName"}})],1),_c('a-button',{staticClass:"talents_condition_btnCheck",attrs:{"type":"primary","shape":"round","html-type":"submit"},on:{"click":_vm.searchQuery}},[_vm._v("查询")]),_c('a-button',{staticClass:"talents_condition_btnReset",staticStyle:{"background":"#b1b1b1"},attrs:{"shape":"round","html-type":"submit"},on:{"click":_vm.reset}},[_vm._v("重置")])],1),_c('div',{staticClass:"talents_table"},[_c('a-table',{attrs:{"loading":_vm.rankingData.rankingLoading,"columns":_vm.rankingData.columns,"data-source":_vm.rankingData.data,"size":"middle","pagination":false,"rowKey":(record) => record.id},scopedSlots:_vm._u([{key:"index",fn:function(record, index, indent){return [_c('span',{staticClass:"weight__border"},[_vm._v(_vm._s((_vm.rankingData.pagination.current - 1) * _vm.rankingData.pagination.pageSize + Number(indent) + 1))])]}},{key:"action",fn:function(text, record, index){return [_c('a',{on:{"click":function($event){return _vm.toDetail(record, index)}}},[_vm._v("查看详情")])]}}])}),(_vm.rankingData.data.length > 0)?_c('div',{staticClass:"ranking_page_box"},[_c('div',{class:[
            'ranking_page_first',
            _vm.currentNumFirst ? '' : 'noCursor',
            _vm.rankingData.rankingLoading ? 'btn_disabled' : '',
          ],on:{"click":_vm.firstBtn}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"ranking_page_center"},[_c('a-pagination',{attrs:{"total":_vm.rankingData.pagination.total,"pageSize":_vm.rankingData.pagination.pageSize,"disabled":_vm.rankingData.rankingLoading},on:{"change":_vm.changeRankPage},model:{value:(_vm.rankingData.pagination.current),callback:function ($$v) {_vm.$set(_vm.rankingData.pagination, "current", $$v)},expression:"rankingData.pagination.current"}})],1),_c('div',{class:[
            'ranking_page_end',
            _vm.currentNumEnd ? '' : 'noCursor',
            _vm.rankingData.rankingLoading ? 'btn_disabled' : '',
          ],on:{"click":_vm.endBtn}},[_vm._v(" 尾页 ")])]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }